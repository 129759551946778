import './App.css';
import { useState, useEffect } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import RouteIcon from '@mui/icons-material/Route';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import Container from '@mui/material/Container';
import Devlog from './Devlog'
import Details from './Details'
import Roadmap from './Roadmap'

var navigations = [
  {
    label: "Details",
    icon: <DarkModeIcon />,
    activeColor: '#550088',
    component: <Details />,
  },
  {
    label: "RoadMap",
    icon: <RouteIcon />,
    activeColor: '#88aaff',
    component: <Roadmap />,
  },
  {
    label: "Devlogs",
    icon: <LogoDevIcon />,
    activeColor: '#6aba4a',
    component: <Devlog />,
  }
]

function GetSvgRand()
{
  let svgX = Math.floor(Math.random() * 90) + 5
  let svgY = Math.floor(Math.random() * 95) + 5

  let svgSize = Math.floor(Math.random() * 2) + 2

  let aniDur = Math.random() * 3 + 3

  return <svg viewBox="0 0 24 24" fill="#f9f06b" stroke="#000000" width={String(svgSize + '%')} style={{ position: 'absolute', left: String(svgX + '%'), top: String(svgY + '%'), animationDuration: String(aniDur + 's') }}  className='stars'><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 2L14.012 7.23109C14.294 7.96432 14.435 8.33093 14.6542 8.63931C14.8486 8.91262 15.0874 9.15141 15.3607 9.34575C15.6691 9.56503 16.0357 9.70603 16.7689 9.98804L22 12L16.7689 14.012C16.0357 14.294 15.6691 14.435 15.3607 14.6542C15.0874 14.8486 14.8486 15.0874 14.6542 15.3607C14.435 15.6691 14.294 16.0357 14.012 16.7689L12 22L9.98804 16.7689C9.70603 16.0357 9.56503 15.6691 9.34575 15.3607C9.15141 15.0874 8.91262 14.8486 8.63931 14.6542C8.33093 14.435 7.96432 14.294 7.23109 14.012L2 12L7.23108 9.98804C7.96431 9.70603 8.33093 9.56503 8.63931 9.34575C8.91262 9.15141 9.15141 8.91262 9.34575 8.63931C9.56503 8.33093 9.70603 7.96431 9.98804 7.23108L12 2Z" stroke="#f9f06b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
}

export default function App() {
  const [value, setValue] = useState(1);
  const [svgs, setSvgs] = useState([]);
  const [mousePos, setMousePos] = useState([0, 0]);
  const [clicked, setClicked] = useState(false);
  
  useEffect(() => {

    if (!svgs.length) {
    setSvgs(Array(13).fill(0).map(() => (
      <GetSvgRand />
    )))};

    const mouseMove = (e) => {

      if (Math.hypot(e["pageX"] - mousePos[0], e["pageY"] - mousePos[1]) > 80)
      {
        setMousePos([e["pageX"], e["pageY"]])
  
      let svgSize = Math.floor(Math.random() * 10) + 15;
      let svgRot = Math.floor(Math.random() * 360);
      let svgLeft = Math.floor(e["pageX"] - svgSize / 2).toString() + "px";
      let svgTop = Math.floor(e["pageY"] - svgSize / 2).toString() + "px";
  
      let elem = document.createElement("img");
  
      elem.className = "cursorStar";
      elem.src = require('./assets/images/starSvg.svg').default;
      elem.style.width = svgSize.toString() + "px";
      elem.style.height = svgSize.toString() + "px";
      elem.style.left = svgLeft
      elem.style.top = svgTop
      elem.style.transform = 'rotate(' + svgRot.toString() + 'deg)'
      elem.style.filter = 'invert(100%) sepia(30%) saturate(3667%) hue-rotate(' + Math.floor(Math.random() * 255).toString() + 'deg) brightness(100%) contrast(100%)';
      
      
      elem.addEventListener('animationend', function(e) { 
        elem.remove();
      });
      
      document.body.appendChild(elem);
    }
    }

    const handleClick = (e) => {
      setClicked(true)

      let elem = document.getElementById('click-star');
      elem.style.left = (e["pageX"] - 20).toString() + 'px';
      elem.style.top = (e["pageY"] - 20).toString() + 'px';
    }

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener(
        'mousemove',
        mouseMove
      );
      window.removeEventListener(
        'mousedown',
        handleClick
      );
    };
  }, [mousePos, svgs]);

  return (
        <Container component="main" sx={{ mb: '5%' }} maxWidth="lg" minHeight="500px" id="mainContainer">
          <img id='click-star' src={require('./assets/images/starSvg.svg').default} alt="" style={{position: 'absolute', opacity: '0%'}} className={clicked ? 'clickStar' : ''} onAnimationEnd={() => setClicked(false)}></img>
          {svgs}
          <img src={require('./assets/images/Logo.png')} width={'100%'} alt="Game Logo" className='game-logo' />
          <BottomNavigation
            showLabels
            value={value}
            sx={{ borderRadius: 2}}
            className='navbar'
            onChange={(event, newValue) => {
              setValue(newValue);
              document.getElementById('component-container').scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }}
          >
            {navigations.map((tab, index) => (
              <BottomNavigationAction label={tab.label} icon={tab.icon} style={{color: value === index ? tab.activeColor : '#666666'}} />
            ))}
          </BottomNavigation>
          <Container className='component' key={value} id='component-container' >
            {navigations[value].component}
          </Container>
        </Container>
  );
}
