import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './App.css';

export default function Details() {

  return (
    <Grid container spacing={5} columns={12}>
      <Grid item xs={12}>
        <Box textAlign={'center'}>
          <Typography style={{ fontFamily: "'Monoton', cursive" }} color={'white'} className='title-text' fontWeight={"bold"} fontFamily={"times-bold"} variant="h2">Welcome&nbsp;&nbsp;&nbsp;&nbsp;To<br/>The&nbsp;&nbsp;&nbsp;&nbsp;Junimo&nbsp;&nbsp;&nbsp;&nbsp;Galaxy!</Typography>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent='center' alignItems={'center'} textAlign='center'>
          <Typography className='subtitle subtitle-extra' variant="h4" style={{ fontFamily: "'CarterOne', cursive" }} >A 3D multiplayer game in space!</Typography>
          <Typography className='content-text content-text-extra' variant="h5" style={{ fontFamily: "'Koulen', cursive" }} >
          &bull; 3D environment and planets &bull;<br />
          &bull; Build your own space station &bull;<br />
          &bull; Play and chat with friends and other people &bull;<br />
          &bull; Customize your home and your character &bull;
          </Typography>

          <Typography className='subtitle subtitle-extra' variant="h4" style={{ fontFamily: "'CarterOne', cursive" }} >Game and website are always being worked on and developed</Typography>
          <Typography className='content-text content-text-extra' variant="h5" style={{ fontFamily: "'Koulen', cursive" }} >
            See what's planned to come in the "Road Map" tab<br />
            Read in details what parts of the game are already finished in the "Devlogs" tab<br />
            Watch some code snippets and funny moments while developing on my YouTube channel
          </Typography>

          <Typography className='subtitle subtitle-extra' variant="h4" style={{ fontFamily: "'CarterOne', cursive" }} >Contact me</Typography>
          <Typography className='content-text content-text-extra' variant="h5" style={{ fontFamily: "'Koulen', cursive" }} >
            For any questions or suggestions you can contact me at:<br/>
            enchantedmaplellc@gmail.com<br />
            Or even through comments on my <a href={"https://www.youtube.com/channel/UCHjkeAqTFOvxwYTG5eDEfKA"} target={"_blank"} rel={"noreferrer"}> Youtube channel</a><br />
          </Typography>

        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box display={'flex'} flexDirection={'column'}>
          <img src={require('./assets/images/Coin.png')} alt="Coin" style={{marginTop: '110px'}} />
          <img src={require('./assets/images/UnrealLogo.png')} alt="UnrealEngine" style={{marginTop: '110px'}} />
        </Box>
      </Grid>
    </Grid>
  );
}