import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PathPiece from './PathPiece';

import { useEffect } from 'react';

const roadmap = [
  'Game idea and theme',
  'Simple Proof of Concept Project',
  'Making a website to document progress',
  'Basic game elements (coins, npcs, ...)',
  'Menus, world save and themes',
  'Adding a planet with a complete event',
  'Populate with sounds, models, npcs and more',
  'Adding a second planet including everything',
  'Polish, bug fixes, code improvment and cleanup',
  'Have a working and finished demo',
  'Demo released as singleplayer to public'
];

export default function CustomizedSteppers() {

  useEffect(() => {

    function updateSize() {
      
      let containers = document.getElementsByClassName('round-gradient');
      let divs = document.getElementsByClassName('path-piece-container');

      for(let i = 0; i < divs.length; i++) {

        divs[i].style.maxWidth = String(window.screen.width / 4.5) + 'px';
        let horizontalAlign = String(containers[i + 1].getBoundingClientRect().width / 3.25 - divs[i].getBoundingClientRect().width / 2) + 'px';
        
        if(i % 2 === 0) divs[i].style.right = horizontalAlign;
        else divs[i].style.left = horizontalAlign;
        divs[i].style.top = String(containers[i + 1].getBoundingClientRect().height / 1.6 - divs[i].getBoundingClientRect().height) + 'px';

      }

      let clouds = document.getElementsByClassName('cloud');

      for(let i = 0; i < clouds.length; i++) {
        let size = (Math.random() * 115 + 85) / 1000 * window.screen.width;

        clouds[i].style.width = String(size) + "px";
        clouds[i].style.height = String(size / 2) + "px";
       }

    }

    window.addEventListener('resize', updateSize);
    
    return () => window.removeEventListener('resize', updateSize);

    }, []);

  return (
    <Box display={'flex'} alignContent={'center'} alignItems='center' justifyContent={'center'} justifyItems='center' flexDirection={'column'} textAlign='center'>
      <Typography className='title-text title-text-extra' variant="h2" style={{ fontFamily: "'Monoton', cursive" }} color={'white'}>Development&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Roadmap</Typography>
      <Typography className={'round-gradient content-text content-text-extra'} style={{ fontFamily: "'CarterOne', cursive" }} color={'white'} variant="h4" sx={{ mt: '4%' }}>
        This is my plan for the development of The Junimo Galaxy
        <br />
        The steps are guidlines and some changes may occur during the development
        <br />
        Also there won't be estimated dates since my development times can vary alot
      </Typography>
        {roadmap.map((piece, index) => (
          <PathPiece flip={index % 2 === 0} text={piece} elemId={'PathPiece' + String(index)} first={index === 0} active={index < 6} />
        ))}
    </Box>
  );
}