import { useEffect } from 'react';
import './PathPiece.css'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';


export default function PathPiece(props) {

    useEffect(() => {

        let div = document.getElementById(props.elemId);
        let container = document.getElementById(props.elemId + 'c');

        //let startPoint = props.flip ? container.getBoundingClientRect().width - 320 : 330;

        //div.style.left = '17%';//String(startPoint - div.getBoundingClientRect().width / 2) + 'px';
        div.style.maxWidth = String(window.screen.width / 4.5) + 'px';
        let horizontalAlign = String(container.getBoundingClientRect().width / 3.25 - div.getBoundingClientRect().width / 2) + 'px';
        
        if(props.flip) div.style.right = horizontalAlign;
        else div.style.left = horizontalAlign;
        div.style.top = String(container.getBoundingClientRect().height / 1.6 - div.getBoundingClientRect().height) + 'px';
        div.style.animationDelay = String(Math.random() * -3) + 's';

        const bgIndexes = new Set();
        while(bgIndexes.size !== 3) {
            bgIndexes.add(Math.floor(Math.random() * 6));
        }

        for (let i = 0; i < 5; i++) {

            let elem = document.createElement("img");
            let animdur = Math.random() * 60 + 30;
            let size = (Math.random() * 115 + 85) / 1000 * window.screen.width;

            elem.className = "cloud";
            elem.src = require('./assets/images/cloud.png');
            elem.alt = '';
            elem.style.width = String(size) + "px";
            elem.style.height = String(size / 2) + "px";
            elem.style.animationDuration = String(animdur) + 's';
            elem.style.animationDelay = String(Math.random() * -animdur) + 's';
            elem.style.top = String(i * 15 + 10) + '%';
            elem.style.zIndex = bgIndexes.has(i) ? '-1' : '1';
    
            container.appendChild(elem);
        }

      }, [props]);

      const pic = props.first ? require('./assets/images/SitePlatformStart.png') : props.active ? require('./assets/images/SitePlatform.png') : require('./assets/images/SitePlatformGray.png');

  return (
    <Container id={props.elemId + 'c'} display={'flex'} className={'round-gradient'} style={{position: 'relative'}} >
        <img src={pic} alt='' style={{width: '100%', height: '100%', transform: 'scale(' + (props.flip ? '-1' : '1') + ', 1)'}} />
        <div className={'path-piece-container'} id={props.elemId} >
            <div className={'path-piece-bg-div'}>
                <div className={'path-piece-div'}>
                    <Typography maxWidth={'220px'} color={'#550099'} variant={'h4'}>{props.text}</Typography>
                </div>
            </div>
        </div>
    </Container>
  );
}