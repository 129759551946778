import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';

import './Devlog.css'

const GifDisplay = (props) => {
  return (
    <>
      <img className='' src={require('./assets/gifs/' + props.name)} alt={props.desc} style={{ display: 'block', margin: '20px auto 20px auto', width: '70%'}} />
      <Typography sx={{ color: 'text.secondary' }} style={{ textAlign: 'center'}}>
        {props.subtext}
      </Typography>
    </>
  );
}

var logsDetails = [
  {
    date: "3/2/2023",
    title: "The idea for the game",
    subtitle: "Devlog #0",
    content: 
    <AccordionDetails>
      <Typography>
        <br/>As the first devlog, this one won't be very technical and would probably be the longest so for a TL;DR version, go to the bottom of the post.
        <br/>
        <br/>In the past, I have already done some projects and small POC games but I have decided to invest myself into this one as I feel very passionate about it.
        <br/>
        <br/>I also already want to try and test my skills in a bigger scaled production and to learn about game publishing and marketing which is why I made this site.
        <br/>
        <br/>So, what is the game I'm gonna try and make?
        <br/>
        <br/>I want to make a 3D multiplayer game which will take place in space on the same general idea of games like Club Penguin where there are multiple locations on the map the player can go to and see, interact and play with other players or alone.
        <br/>The locations will be different planets you can go around, each one offering different games/quests/npcs and unique aesthetic.
        <br/>The games on each planet are planned to be more like "planet events" as the idea is to have it happen across the planet rather than taking the player to a different space or opening a game menu, so all the players on the island can simultaneously participate in the game.
        <br/>
        <br/>The player himself would be able to buy and wear different clothes and customize his character (the plan as of writing this is for the player to be a robot/alien/something else or even to be able to be all of them).
        <br/>Alongside the character, each player will also have his own "space station" - A home he can create, customize and share using different building blocks he would be able to purchase using the game's currency.
        <br/>Players will be able to visit each other's space stations.
        <br/>
        <br/>Some 'more technical' info:
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • The game's currency will probably be just simple coins and special coins, but at some point I'll experiment with using maybe a subscription plan or even replace coins with gears and scraps to buy and build.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • The game will have a more cartoony aesthetic.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • The game will be marketed mostly towards kids and teenagers.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • The engine I'll be using for this project is Unreal Engine 5.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • The game will probably require decent hardware and graphics card but I'll try making it available to as many people as possible.
        <br/>
        <br/>TL;DR version:
        <br/>I'm making a 3D cartoony space game where players are going between unique planets, playing in events, talking to each other, creating a character and a space station that other's can visit.
        <br/>The game is created using UE5.
      </Typography>
    </AccordionDetails>,
  },
  {
    date: "3/2/2023",
    title: "Preparing the ropes",
    subtitle: "Devlog #1",
    content: 
    <AccordionDetails>
      <Typography>
        <br/>The first thing that I wanted to implement are the basic concepts of where the player can be - either on a planet or in his space station.
        <br/>The planets need to have custom gravity and the space stations need to have basic base building system prototype.
        <br/>
        <br/>I started with the space station part, making a quite simple building system that allows the player to build a base using rooms and tubes that change based on neighboring ones:
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="PreparingTheRopes_Gif1.gif" desc="SpaceStationShowcaseGif" subtext={<>Building an example base using the system<br/>Obviously all models are placeholders, I also experimented with skyboxes</>} />
      <Typography>
        <br/>
        <br/>After that I began working on the planets' gravity. For that I followed a tutorial from the YT creator "Code Like Me" to get the basic idea and implementation and I altered it to fix some bugs and add a basic jump function.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="PreparingTheRopes_Gif2.gif" desc="PlanetShowcaseGif" subtext={<>Moving using the custom planet's gravity system</>} />
      <Typography>
        <br/>
        <br/>Even tho the systems are pretty simple, it took me quite a while to get them right since I had to make sure they will be able to support the game when it gets bigger.
        <br/>For example, I rebuilt the entire rooms connections' system 3 times in order to make the base much more customizable to the player as well as trying a lot of jump implementations for the planet gravity to check which one works best.
      </Typography>
    </AccordionDetails>,
  },
  {
    date: "5/4/2023",
    title: "New base building systems",
    subtitle: "Devlog #2",
    content: 
    <AccordionDetails>
      <Typography>
        <br/>While the building system from the last devlog looks and feels good, it had some problems such as difficulty building larger scaled bases and not supporting deleting placed rooms or building on multiple floors.
        <br/>
        <br/>In order to solve these problems, I've changed to a new system which now puts the player in a more focused building state when wanting to build:
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="BuildAndFurnishMode_Gif1.gif" desc="FocusedBuildingGif" subtext={<>The new "focused building" system</>} />
      <Typography>
        <br/>
        <br/>In the new system, you can move freely to the sides, as well as zoom in and out, delete structures and spawn them in a specified rotation
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="BuildAndFurnishMode_Gif2.gif" desc="RotatingStructuresGif" subtext={<>Example of rotating the structures<br/><br/><br/></>} />
      <GifDisplay name="BuildAndFurnishMode_Gif3.gif" desc="CancelingChangesGif" subtext={<>Example of canceling the changes made</>} />
      <Typography>
        <br/>
        <br/>At any time, you can decide to cancel to revert the changes made or save to exit the building mode. However, note that there has to be a path that connects all the structures in order to save the base so it can't be split into more than one part. The system can also handle zooming in or out and cases where the block the player stood on changed or was deleted.
        <br/>
        <br/>
        <br/>A new feature that was added is the ability to place furniture inside the base so the player can decorate it however he likes. The isn't fully complete yet but more things need to come before I can expand it.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="BuildAndFurnishMode_Gif4.gif" desc="FurnitureSystemGif" subtext={<>The new furniture system</>} />
      <Typography>
        <br/>With this devlog, I also opened a new Gmail and a Youtube channel called EnchantedMaple
        <br/>
        <br/>If you want to check out the intro video I made for the game and maybe other stuff that i'll post along the way, feel free to check out the channel by using the links in the “Details” tab.
        <br/>
        <br/>
      </Typography>
    </AccordionDetails>,
  },
  {
    date: "13/5/2023",
    title: "Adding game elements",
    subtitle: "Devlog #3",
    content: 
    <AccordionDetails>
      <Typography>
        <br/>In this devlog I focused on adding some much needed game elements that will be used to shape the user experience over the different worlds.
        <br/>
        <br/>The first and most difficult element I added was an NPCs and dialogue system. NPCS will be placed in different places across the worlds and each one can have different conversations’ trees with options that lead to different parts of the dialogue.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="AddingGameElements_Gif1.gif" desc="NPCDialogueGif" subtext={<>An example of some conversations with an NPC</>} />
      <Typography>
        <br/>
        <br/>Another element I wanted to add is an inventory system of structures, so when in Build Mode, you have a limited amount of different structures to place down. This way, players won’t be able to make bases that go to infinity and will have to work for the structures they buy in order to expand their space station.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="AddingGameElements_Gif2.gif" desc="StructuresInventoryGif" subtext={<>Building using the structures inventory system</>} />
      <Typography>
        <br/>
        <br/>The rest of the things that were implemented were simpler and smaller so I'll list them all since they don't need explanation:
        <br/>
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • A map you can open and go to all other worlds with
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • Simple coins you can pick up that increase the amount of money you have
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • A game instance which can save and load data such as the users' money and space station when going to other worlds (only saves per game run - not to a file)
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • In build mode, added a preview of the structure for easier building and visualization
        <br/>&nbsp;&nbsp;&nbsp;&nbsp; • Improved the building mode's UI layout and assets
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="AddingGameElements_Gif3.gif" desc="CoinsAndMapGif" subtext={<>The new coins and map<br/><br/><br/></>} />
      <GifDisplay name="AddingGameElements_Gif4.gif" desc="BuildModeUIAndPreviewGif" subtext={<>New UI and a preview structure in Build Mode</>} />
      <Typography>
        <br/>With these elements, this project is really starting to look like a game which is pretty cool to me and hopefully I'll be able to continue on this path.
        <br/>
        <br/>The next devlog will also be about different game elements that need to be added, so stay tuned.
        <br/>
        <br/>
      </Typography>
    </AccordionDetails>,
  },
  {
    date: "11/8/2023",
    title: "Making the game an actual game",
    subtitle: "Devlog #4",
    content: 
    <AccordionDetails>
      <Typography>
        <br/>Hello! Been a while since the last devlog… I was going through weird times which didn’t allow me to work on the game as much as I would’ve wanted to plus some annoying bugs while making the game, some of which took me days to figure out. But, finally I completed another part of the game so here’s what I was doing during this time:
        <br/>
        <br/>In this chapter I mostly focused on things that are an essential part of almost any game.
        <br/>
        <br/>I added a save and load from file option, this way you can continue your game over multiple sessions.
        <br/>In the future, when the game will be multiplayer, everything will be saved on the server and every change made will be saved. Because of that, for the demo there is only one save file.
        <br/>
        <br/>To go with the saving and loading, I made a title screen as a place when starting up the game.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="Devlog4_Gif1.gif" desc="SaveAndLoadGif" subtext={<>Title screen and loading a saved game. The game is saved even after closing the window.</>} />
      <Typography>
        <br/>
        <br/>In the last devlog I made an inventory system for the structures on Build Mode, but there was nowhere to buy these. Now, NPCs can have shops you can access to buy more structures to place on your space station.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="Devlog4_Gif2.gif" desc="ShopShowcaseGif" subtext={<>Buying new structures and using them to expand the space station. You can buy 5 at a time by pressing Shift.</>} />
      <Typography>
        <br/>
        <br/>You can also check the time lapse of creating the title screen by going to <a href={"https://www.youtube.com/watch?v=Lvsw5UNavjg"} target={"_blank"} rel={"noreferrer"}>this video</a> on the studio’s channel. There are also the original videos of the save/load and shop gifs if you want to see them in better quality. For the save/load video, <a href={"https://youtu.be/EvVEBBfkWgM"} target={"_blank"} rel={"noreferrer"}>go here</a> and for the shop one, <a href={"https://youtu.be/9Aq0thOzxZQ"} target={"_blank"} rel={"noreferrer"}>go here</a> (unlisted videos)
        <br/>
        <br/>Last but not least, the player now has it's own inventory where he can see himself and his items. When the player will have a new model, you will be able to change its' appearance and equip clothing or gadgets through this menu.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="Devlog4_Gif3.gif" desc="InventoryShowcaseGif" subtext={<>The new player's inventory menu, you can even grab the player model to spin it around!</>} />
      <Typography>
        <br/>
        <br/>Hopefully, future devlogs won't take that much time so you can keep getting posted and updated on the progress made on the game, but that’s it for this devlog. Next devlog should sum up all the other “game essentials” systems.
        <br/>
        <br/>
      </Typography>
    </AccordionDetails>,
  },
  {
    date: "16/12/2023",
    title: "A turning point!",
    subtitle: "Devlog #5",
    content: 
    <AccordionDetails>
      <Typography>
        <br/>As the title suggests, this devlog is a crucial turning point since it marks the finished development of the game’s structure - All the basic game mechanics, menus and gameplay concepts which means that from now on development will be focused mostly on physical hard gameplay such as designing planets, assets, items, characters as well as other things like additional structures, quests and of course planet events / games.
        <br/>
        <br/>But all these things will come in the future so for now, let's focus on what was added in this devlog:
        <br/>
        <br/>An options menu was added allowing the user to change a few game settings such as the mouse sensitivity and keyboard controls.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="Devlog5_Gif1.gif" desc="SaveAndLoadGif" subtext={<>Changing settings and controls in the options menu</>} />
      <Typography>
        <br/>
        <br/>Additionally the inventory system, which had a basic implementation in the last devlog, now actually supports having and equipping items.
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="Devlog5_Gif2.gif" desc="ShopShowcaseGif" subtext={<>Equipping different clothing and hand items from the player’s inventory tab</>} />
      <Typography>
        <br/>
        <br/>With these upgrades I've also re-thought and settled on a new style for the game and changed all the assets to fit it.
        <br/>
        <br/>And as a final touch other systems and the entire project layout were reconstructed which should make development easier and faster from now on.
        <br/>
        <br/>You can see all these new changes and everything else my game has to offer in the <a href={"https://youtu.be/Mj-c2Pc7CcM"} target={"_blank"} rel={"noreferrer"}>new video I posted on the channel</a>. It shows everything that has happened in all the devlogs so far in a non-GIF, high frame rate, high resolution, single video format :)
        <br/>
        <br/>And with that, this devlog and chapter in development are done. So far I can really see the potential of this game and I'll do my best to try and get it to it.
        <br/>
        <br/>Devlogs from now on should be more exciting, so I hope you look forward to them.
        <br/>
        <br/>
      </Typography>
    </AccordionDetails>,
  },
  {
    date: "1/2/2024",
    title: "News and progress",
    subtitle: "Devlog #5.5",
    content: 
    <AccordionDetails>
      <Typography>
        <br/>As you can see, this devlog isn’t the big progress devlog #6 that was supposed to be next. Instead, it’s meant to be more of an update post to let you know a few things.
        <br/>
        <br/>This devlog will take longer than anticipated mostly due to me getting sick, having less and less free time to work on the game and some development problems that are taking me more time than I initially anticipated.
        <br/>
        <br/>But, there is also good news! I found out I can work on a few things related to the game even at times when I can’t work on it directly. Mostly, the website and some designing.
        <br/>This is why the website now has a new look and will be developed further in the future for things like better mobile compatibility and even a user system. Website updates won’t be paired with a devlog since these are mostly for showing new game content.
        <br/>
        <br/>Still, I want to share some progress photos of the first planet so you still have something new to see:
        <br/>
        <br/>
      </Typography>
      <GifDisplay name="Devlog5.5-1.JPG" desc="CrystalPic" subtext={<>mystery crystal oooooh .o.<br/><br/></>} />
      <GifDisplay name="Devlog5.5-2.JPG" desc="PlatformsPic" subtext={<>mystery platforms aaaaah ;D<br/><br/></>} />
    </AccordionDetails>,
  },
].reverse()

const LogsBackgroundDiv = styled('div')(({ theme }) => ({
  borderRadius: 15,
  padding: '2%',
  backgroundImage:
    'linear-gradient( 136deg, rgb(247, 241, 255) 0%, rgb(219, 192, 249) 10%, rgb(126, 88, 173) 70%)',
}));

export default function Devlog() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='devlog-div'>
      <Typography className='title-text' style={{ fontFamily: "'Monoton', cursive" }} color={'white'} variant="h2">Developer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Logs</Typography>
      <LogsBackgroundDiv>
        {logsDetails.map((log, index) => (
          <Accordion expanded={expanded === index} onChange={handleChange(index)} style={{ margin: "1%"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={String(index + "-content")}
              id={String(index + "-header")}
            >
                <Box>
                    <Typography className='subtitle' style={{ fontFamily: "'CarterOne', cursive" }} variant='h4' fontWeight='bold' color={'black'}>
                      {log.title}
                    </Typography>
                    <Typography className='content-text' variant='h6' sx={{ color: 'text.secondary', marginTop: '10px' }} color={'black'}>
                      {log.subtitle}
                    </Typography>
                </Box>
            </AccordionSummary>
              {log.content}
          </Accordion>
        ))}
      </LogsBackgroundDiv>
    </div>
  );
}